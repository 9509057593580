import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const UserRoute = ({ component: Component, isAuth, ...rest }) => (
  <Route 
    {...rest}
    render={
      props => isAuth 
        ? <Component {...props} /> 
        : <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>
    }
  />
);

const mapStateToProps = state => ({
  isAuth: state.auth.user
});

export default connect(mapStateToProps)(UserRoute);