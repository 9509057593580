import {
  GET_AREAS_NUMERICS_REQUEST,
  GET_AREAS_NUMERICS_SUCCESS,
  GET_AREAS_NUMERICS_FAILURE,
  UPDATE_TABLE_CELL
} from './areas-numerics.actions';

const initialState = {
  areas: [],
};

const areasNumerics = (state = initialState, action) => {
  switch (action.type) {
    case GET_AREAS_NUMERICS_REQUEST: 
      return { ...state };
    case GET_AREAS_NUMERICS_SUCCESS:
      return { ...state,
        areas: action.payload,
      }
    case GET_AREAS_NUMERICS_FAILURE:
      return { ...state };

    case UPDATE_TABLE_CELL:
      const { id, field, value } = action.payload; 

      return { ...state,
        areas: state.areas.map(area => area.id === id
          ? { ...area,
            [field]: value
          }
          : area)
      }
    default:
      return { ...state };
  }
};

export default areasNumerics;