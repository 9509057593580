import {
  VERIFY_SESSION_SUCCESS,
  VERIFY_SESSION_REQUEST,
  VERIFY_SESSION_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT
} from './auth.actions';

const initialState = {
  user: null,
  error: null,
};

const auth = (state = initialState, action) => {
  switch(action.type) {
    case VERIFY_SESSION_REQUEST:
      return state;

    case VERIFY_SESSION_SUCCESS:
      return { ...state,
        user: { ...action.payload.user }
      }

    case VERIFY_SESSION_FAILURE:
      return { ...state,
        error: action.payload.error,
      }

    case LOGIN_REQUEST:
      return state;

    case LOGIN_SUCCESS:
      return { ...state,
        user: { ...action.payload.user }
      }
    
    case LOGIN_FAILURE:
      return { ...state,
        error: action.payload.error,
      }

    case LOGOUT: 
      return { ...state,
        user: null
      }

    default: 
      return state
  }
}

export default auth;