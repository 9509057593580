import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './views/Pages/Login';
import Page404 from './views/Pages/Page404';
import DefaultLayout from './containers/DefaultLayout';
import { connect } from 'react-redux';
import * as authActions from './resources/auth/auth.actions';
import GuestRoute from './hoc/GuestRoute';
import UserRoute from './hoc/UserRoute';
import './App.scss';

const Loader = <div className="animated fadeIn pt-3 text-center">Loading...</div>;

class App extends Component {

  componentDidMount() {
    const { verifySession } = this.props;

    verifySession();
  }

  render() {
    const { user } = this.props;

    return (
      <BrowserRouter>
        <React.Suspense fallback={Loader}>
          <Switch>
            <GuestRoute exact path="/login" isAuth={user} name="Login Page"  component={Login} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <UserRoute path="/" isAuth={user} name="Home" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

const mapDispatchToProps = {
  verifySession: authActions.verifySession
};

export default connect(mapStateToProps, mapDispatchToProps)(App);