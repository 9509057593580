import {
  GET_AREAS_TRANSLATIONS_REQUEST,
  GET_AREAS_TRANSLATIONS_SUCCESS,
  GET_AREAS_TRANSLATIONS_FAILURE,
  UPDATE_TABLE_CELL,
  CHANGE_SOURCE_LOCALE
} from './areas-translations.actions';

const initialState = { 
  areas: [],
  locales: [],
  sourceLocale: 'de'
}

const areasTranslations = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case GET_AREAS_TRANSLATIONS_REQUEST:
      break;

    case GET_AREAS_TRANSLATIONS_SUCCESS:

      const { areas, locales } = action.payload;

      return {
        ...state,
        areas, locales
      }
    
    case GET_AREAS_TRANSLATIONS_FAILURE:
      return {
        ...state, 
        error: action.payload
      }
      

    case CHANGE_SOURCE_LOCALE:
      return { ...state,
        sourceLocale: action.payload
      }

    case UPDATE_TABLE_CELL:
      const { id, field, value, locale } = action.payload;

      return {
        ...state,
        areas: state.areas.map(area => area.id === id
          ? {
            ...area,
            translations: {
              ...area.translations,
              [field]: {
                ...area.translations[field],
                [locale]: value
              }
            }
          }
          : area
        )
      }
  }

  return newState;
}

export default areasTranslations;