import axios from './../../network/axios';

export const GET_AREAS_PICTURES_REQUEST = "GET_AREAS_PICTURES_REQUEST";
export const GET_AREAS_PICTURES_SUCCESS = "GET_AREAS_PICTURES_SUCCESS";
export const GET_AREAS_PICTURES_FAILURE = "GET_AREAS_PICTURES_FAILURE";
export const DISPLAY_PICTURES_STATS = "DISPLAY_PICTURES_STATS";
export const DISPLAY_AREA_PICTURES = "DISPLAY_AREA_PICTURES";
export const DISPLAY_PICTURE_EDITOR = "DISPLAY_PICTURE_EDITOR";

export const getAreasPicturesRequest = () => ({
  type: GET_AREAS_PICTURES_REQUEST
});

export const getAreasPicturesSuccess = (areas) => ({
  type: GET_AREAS_PICTURES_SUCCESS,
  payload: areas
});

export const getAreasPicturesFailure = () => ({
  type: GET_AREAS_PICTURES_FAILURE
});

export const fetchAreasPictures = () => dispatch => {
  dispatch(getAreasPicturesRequest());

  axios.get('/api/admin/areas/states')
    .then(
      res => dispatch(getAreasPicturesSuccess(res.data.areas)),
      err => dispatch(getAreasPicturesFailure())
    );
};

export const displayPictureStats = () => ({
  type: DISPLAY_PICTURES_STATS
});

export const displayAreaPictures = (areaId) => ({
  type: DISPLAY_AREA_PICTURES,
  payload: areaId
});

export const displayPictureEditor = (pictureId) => ({
  type: DISPLAY_PICTURE_EDITOR,
  payload: pictureId
});