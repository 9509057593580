import axios from '../../network/axios';

import config from '../../config';

export const GET_AREAS_TRANSLATIONS_REQUEST = "GET_AREAS_TRANSLATIONS_REQUEST";
export const GET_AREAS_TRANSLATIONS_SUCCESS = "GET_AREAS_TRANSLATIONS_SUCCESS";
export const GET_AREAS_TRANSLATIONS_FAILURE = "GET_AREAS_TRANSLATIONS_FAILURE";

export const UPDATE_TABLE_CELL = "UPDATE_TABLE_CELL";

export const TRANSLATE_TABLE_CELL_REQUEST = "TRANSLATE_TABLE_CELL_REQUEST";
export const TRANSLATE_TABLE_CELL_SUCCESS = "TRANSLATE_TABLE_CELL_SUCCESS";
export const TRANSLATE_TABLE_CELL_FAILURE = "TRANSLATE_TABLE_CELL_FAILURE";

export const CHANGE_SOURCE_LOCALE = "CHANGE_SOURCE_LOCALE";

const shieldDoubleQuoteChar = str => str.split('').map(char => char === '"' ? '\\"' : char).join('');

export const getAreasTranslationsRequest = () => ({
  type: GET_AREAS_TRANSLATIONS_REQUEST,
  payload: null
});

export const getAreasTranslationsSuccess = (areas, locales) => ({
  type: GET_AREAS_TRANSLATIONS_SUCCESS,
  payload: { areas, locales }
});

export const getAreasTranslationsFailure = error => ({
  type: GET_AREAS_TRANSLATIONS_FAILURE,
  payload: error
});

export const fetchAreasTranslations = () => dispatch => {
  dispatch(getAreasTranslationsRequest());

  axios.get('/api/admin/areas/states')
    .then(
      res => {
        const { locales } = res.data;

        const areas = res.data.areas.map(area => {
          const newArea = { ...area, translations: {}};
          const translatableFields = Object.keys(area.translations[0]).filter(key => !['id', 'locale'].includes(key));

          translatableFields.map(translatableField => {
            newArea.translations[translatableField] = area.translations.reduce((acc, cur) => {
              acc[cur.locale.name] = cur[translatableField];

              return acc;
            }, {});
          });

          return newArea;
        })


        dispatch(getAreasTranslationsSuccess(areas, locales)) 
      },
      err => { console.log("Error while fetching areas!", err); dispatch(getAreasTranslationsFailure(err))}
    );
}

export const translateTableCellRequest = () => ({
  type: TRANSLATE_TABLE_CELL_REQUEST,
});

export const translateTableCellSuccess = () => ({
  type: TRANSLATE_TABLE_CELL_SUCCESS,
});

export const translateTableCellFailure = () => ({
  type: TRANSLATE_TABLE_CELL_FAILURE
});

export const translateTableCell = (text, targetLang, metadata) => dispatch => {
  const { id, field, locale } = metadata;
  const { deepL: { apiURL }} = config;

  dispatch(translateTableCellRequest());

  fetch(`${apiURL}&target_lang=${targetLang}&text=${text}`)
    .then(res => { 
      dispatch(translateTableCellSuccess());
      return res.json();
    })
    .then(res => {
      const value = shieldDoubleQuoteChar(res.translations[0].text);

      updateDatabaseTableCell(id, field, value, locale)
        .then(() => dispatch(updateTableCell(id, field, value, locale)))
    })
}

export const translateTableColumn = (texts, targetLang) => dispatch => {
  const { deepL: { apiURL }} = config;
  const locale = targetLang.toLowerCase();

  Promise.all(texts.map(async (text, textIndex) => {
    const fields = Object.keys(text); // ['name', description'] 
    const values = Object.values(text); // ['Vorarlberg', 'Beschreibung von Vorarlberg']

    const url = `${apiURL}&target_lang=${targetLang}&${values.map(value => `text=${value}`).join('&')}`;

    const response = await (await fetch(url)).json(); // { translations: [{ text: 'Форарльберг' }, { text: 'Описание Форарльберга'}]}

    const updates = response.translations.map((translation, translationIndex) => ({
      id: textIndex + 1,
      field: fields[translationIndex],
      value: shieldDoubleQuoteChar(translation.text),
      locale,
    }));

    updates.forEach(update => { 
      const { id, field, value, locale } = update;
    
      dispatch(updateTableCell(id, field, value, locale)) 
    });

    return updates;
  })).then(res => res.flat())
    .then(res => {
      axios.patch('/api/admin/areas/states', {
        updates: res
      })
    })
};

export const updateTableCell = (id, field, value, locale)  => ({
  type: UPDATE_TABLE_CELL,
  payload: { id, field, locale, value }
});

export const updateDatabaseTableCell = (id, field, value, locale) => {
  return axios.patch('/api/admin/areas/states', {
    updates: [{ id, field, value, locale, type: "translations" }]
  })
}

export const changeSourceLocale = (locale) => ({ 
  type: CHANGE_SOURCE_LOCALE,
  payload: locale
});