import axios from './../../network/axios';

export const GET_AREAS_NUMERICS_REQUEST = "GET_AREAS_NUMERICS_REQUEST";
export const GET_AREAS_NUMERICS_SUCCESS = "GET_AREAS_NUMERICS_SUCCESS";
export const GET_AREAS_NUMERICS_FAILURE = "GET_AREAS_NUMERICS_FAILURE";

export const UPDATE_TABLE_CELL = "UPDATE_TABLE_CELL";

export const getAreasNumericsRequest = () => ({
  type: GET_AREAS_NUMERICS_REQUEST
});

export const getAreasNumericsSuccess = (areas) => ({
  type: GET_AREAS_NUMERICS_SUCCESS,
  payload: areas
});

export const getAreasNumericsFailure = () => ({
  type: GET_AREAS_NUMERICS_FAILURE
});

export const fetchAreasNumerics = () => dispatch => {
  dispatch(getAreasNumericsRequest());

  axios.get('/api/admin/areas/states')
    .then(
      res => dispatch(getAreasNumericsSuccess(res.data.areas)),
      err => dispatch(getAreasNumericsFailure())
    );
};

export const updateTableCell = (id, field, value) => ({
  type: UPDATE_TABLE_CELL,
  payload: { id, field, value }
});