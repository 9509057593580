import {
  GET_AREAS_PICTURES_REQUEST,
  GET_AREAS_PICTURES_SUCCESS,
  GET_AREAS_PICTURES_FAILURE,
  DISPLAY_PICTURES_STATS,
  DISPLAY_AREA_PICTURES,
  DISPLAY_PICTURE_EDITOR,
} from './areas-pictures.actions';

const initialState = {
  areas: [],
  displayedComponent: 'picturesStats', // ['picturesStats', 'areaPictures', 'pictureEditor']
  currentAreaID: null,
  currentPictureIDUnderEdition: null
};

const areasPictures = (state = initialState, action) => {
  switch (action.type) {
    case GET_AREAS_PICTURES_REQUEST:
      return { ...state };

    case GET_AREAS_PICTURES_SUCCESS:
      return { ...state,
        areas: action.payload
      };

    case GET_AREAS_PICTURES_FAILURE:
      return { ...state };

    case DISPLAY_PICTURES_STATS:
      return { ...state, 
        displayedComponent: 'picturesStats',
      }
    case DISPLAY_AREA_PICTURES:
      return { ...state,
        displayedComponent: 'areaPictures',
        currentAreaID: action.payload
      }
    case DISPLAY_PICTURE_EDITOR:
      return { ...state,
        displayedComponent: 'pictureEditor',
        currentPictureIDUnderEdition: action.payload
      }
    default: 
      return state;
  }
}

export default areasPictures;