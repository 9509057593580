import { combineReducers } from 'redux';
import auth from './auth/auth.reducer';
import areasTranslations from './areas-translations/areas-translations.reducer';
import areasNumerics from './areas-numerics/areas-numerics.reducer';
import areasPictures from './areas-pictures/areas-pictures.reducer';

const rootReducer = combineReducers({ 
  auth,
  areasTranslations,
  areasNumerics,
  areasPictures,
});

export default rootReducer;