import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import * as authActions from './../../../resources/auth/auth.actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class Login extends Component {
  
  state = {
    username: '',
    password: '',
    error: false
  }

  handleUsernameChange = event => this.setState({ username: event.target.value });
  handlePasswordChange = event => this.setState({ password: event.target.value });

  onClickLoginButton = async () => {
    const { username, password } = this.state;
    const { login } = this.props;

    login(username, password)
      .then(() => {
        this.props.history.push('/');
      })
      .catch(() => this.setState({ error: true }))
  }

  render() {
    const { username, password, error } = this.state;

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={this.handleUsernameChange} type="text" placeholder="Username" autoComplete="username" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input onChange={this.handlePasswordChange} type="password" placeholder="Password" autoComplete="current-password" />
                      </InputGroup>
                      { error &&
                        <Row>
                          <Col xs="6">
                            <p style={{ color: "red" }}>Invalid credentials!</p>
                          </Col>
                        </Row>
                      }
                      <Row>
                        <Col xs="6">
                          <Button disabled={!(username && password)} onClick={this.onClickLoginButton} color="primary" className="px-4">Login</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button disabled color="link" className="px-0">Forgot password?</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapDispatchToProps = {
  login: authActions.login
}

export default withRouter(connect(null, mapDispatchToProps)(Login));