import axios from './../../network/axios';

export const VERIFY_SESSION_REQUEST = "VERIFY_SESSION_REQUEST";
export const VERIFY_SESSION_SUCCESS = "VERIFY_SESSION_SUCCESS";
export const VERIFY_SESSION_FAILURE = "VERIFY_SESSION_FAILURE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const verifySessionRequest = () => ({
  type: VERIFY_SESSION_REQUEST,
  payload: {}
});

export const verifySessionSuccess = (user) => ({
  type: VERIFY_SESSION_SUCCESS,
  payload: { user }
});

export const verifySessionFailure = () => ({
  type: VERIFY_SESSION_FAILURE,
  payload: {}
});

export const loginRequest = () => ({
  type: LOGIN_REQUEST,
  payload: {}
});

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: { user }
});

export const loginFailure = () => ({
  type: LOGIN_FAILURE,
  payload: {}
});

export const logoutActionCreator = () => ({
  type: LOGOUT,
  payload: {}
})

export const verifySession = () => dispatch => {
  dispatch(verifySessionRequest());
  
  return axios.get('api/admin/verify')
    .then(
      res => dispatch(verifySessionSuccess(res.data.user)),
      err => { dispatch(verifySessionFailure()); throw err; }
    )
};

export const login = (username, password) => dispatch => {
  dispatch(loginRequest());

  return axios.post('api/admin/login', { username, password })
    .then(
      res => { dispatch(loginSuccess(res.data.user)); },
      err => { dispatch(loginFailure()); throw err}
    );
};

export const logout = () => dispatch => {
  return axios.get('api/admin/logout')
    .then(
      res => dispatch(logoutActionCreator())
    );
};